/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { FormattedMessage } from 'gatsby-plugin-intl'

import FlexContainer from '../components/FlexContainer'
import Layout from '../components/Layout'
import AddressForm from '../components/pages/Perfil/AddressForm'
import CreditCardForm from '../components/pages/Perfil/CreditCardForm'
import ProfileForm from '../components/pages/Perfil/ProfileForm'
import Spacing from '../components/Spacing'
import Title from '../components/Title'
import Wrapper from '../components/Wrapper'

const ProfilePage = () => {
  return (
    <Layout>
      <Wrapper>
        <Spacing top="40" bottom="60">
          <FlexContainer
            css={theme => css`
              ${theme.queries.medium} {
                align-items: center;
                flex-direction: row;
              }
            `}
          >
            <Title as="h3" color="celestialblue">
              <FormattedMessage id="pages.profile.title" />
            </Title>
          </FlexContainer>
          <ProfileForm />
          <AddressForm />
          <CreditCardForm />
        </Spacing>
      </Wrapper>
    </Layout>
  )
}

export default ProfilePage
