/* eslint-disable camelcase */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'

import PropTypes from 'prop-types'
import Cards from 'react-credit-cards'

import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import { getCreditCard, setCreditCard } from '../../../../../services/profile'

import {
  getCreditCards as getCardsAction,
  getCreditCardsRequest,
} from '../../../../../store/modules/user/actions'

import Button from '../../../../Button'

import 'react-credit-cards/lib/styles.scss'
import Loading from '../../../../../images/icons/loadingbutton.svg'

import { Form, FormControl, FormGroupColumn, SelectButton } from './styles'
import { getSiteCurrentLanguage } from '../../../../../utils/getLanguage'
import { LANGUAGE } from '../../../../../utils/enum/language'

const CreditCard = ({ goBack }) => {
  const [isLoading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const intl = useIntl()

  const [showCPFField, setShowCPFField] = useState(false)

  useEffect(() => {
    const isLanguagePortuguese =
      getSiteCurrentLanguage(intl) === LANGUAGE.portuguese

    setShowCPFField(isLanguagePortuguese)
  }, [])

  const [cvc, setCvc] = useState('')
  const [expiry, setExpiry] = useState('')
  const [focus, setFocus] = useState('')
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')

  const handleInputFocus = e => {
    setFocus(e.target.name)
  }

  const handleInputChange = e => {
    const { name, value } = e.target

    switch (name) {
      case 'name':
        setName(value)
        break
      case 'cvc':
        setCvc(value)
        break
      case 'expiry':
        setExpiry(value)
        break
      case 'focus':
        setFocus(value)
        break
      case 'number':
        setNumber(value)
        break
      default:
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()

    setLoading(true)

    const [expiration_month, expiration_year] = expiry.split('/')

    try {
      await setCreditCard({
        holder_name: name,
        expiration_month,
        expiration_year: `20${expiration_year}`,
        security_code: cvc,
        card_number: number,
      })

      dispatch(getCreditCardsRequest())

      toast.success('Cartão adicionado com sucesso')
    } catch (error) {
      toast.error(error.Message || 'Erro ao adicionar o cartão')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      css={theme => css`
        ${theme.queries.medium} {
          position: relative;
          display: flex;
          justify-content: space-between;
          flex: 1;
          margin-right: 200px;
        }
      `}
    >
      <Form id="credit-card-form" onSubmit={handleSubmit}>
        <FormControl>
          <label>
            <FormattedMessage id="pages.donation.credit_card_form.fullName" />
          </label>
          <input
            type="text"
            name="name"
            onChange={e => handleInputChange(e)}
            onFocus={e => handleInputFocus(e)}
            css={css`
              text-transform: uppercase;
            `}
          />
        </FormControl>
        {showCPFField && (
          <FormControl>
            <label>CPF/CNPJ</label>
            <input
              type="text"
              name="document"
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          </FormControl>
        )}
        <FormControl>
          <label>
            <FormattedMessage id="pages.donation.credit_card_form.card_number" />
          </label>
          <input
            type="text"
            name="number"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </FormControl>
        <FormGroupColumn>
          <FormControl className="sameLine">
            <label>
              <FormattedMessage id="pages.donation.credit_card_form.valid_thru" />
            </label>
            <InputMask
              mask="99/99"
              name="expiry"
              type="text"
              onChange={e => handleInputChange(e)}
              onFocus={e => handleInputFocus(e)}
            />
          </FormControl>
          <FormControl>
            <label>CVV</label>
            <InputMask
              mask="999"
              name="cvc"
              type="text"
              onChange={e => handleInputChange(e)}
              onFocus={e => handleInputFocus(e)}
            />
          </FormControl>
        </FormGroupColumn>

        <div
          css={theme => css`
            ${theme.queries.small} {
              display: flex;
              flex-direction: column;
              text-align: left;
            }
          `}
        >
          <SelectButton
            type="button"
            onClick={goBack}
            style={{ textAlign: 'left' }}
          >
            <FormattedMessage id="credit_card_component.add.cancel" />
          </SelectButton>

          <Button
            type="submit"
            css={theme => css`
              width: 280px;

              ${theme.queries.small} {
                margin-top: 30px;
              }

              ${theme.queries.medium} {
                position: absolute;
                right: -200px;
              }
            `}
          >
            {isLoading ? <Loading /> : 'Salvar'}
          </Button>
        </div>
      </Form>
      <div
        css={theme => css`
          ${theme.queries.small} {
            display: none;
          }

          ${theme.queries.medium} {
            /* bottom: 30px;
            left: calc(50% + 240px);
            position: absolute;
            width: 497px;
            height: 293px; */

            & > div > div {
              width: 497px;
              height: 293px;
            }
          }
        `}
      >
        <Cards
          cvc={cvc}
          expiry={expiry}
          focused={focus}
          name={name}
          number={number}
        />
      </div>
    </div>
  )
}

CreditCard.propTypes = {
  goBack: PropTypes.func.isRequired,
}

export default CreditCard
