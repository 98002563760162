/** @jsx jsx */
import { jsx, css } from '@emotion/core'

import { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import CreditCard from './CreditCard'
import Loader from '../../../Loader'

import { removeCreditCard } from '../../../../services/profile'
import {
  removeCreditCard as removeCreditCardState,
  getCreditCardsRequest,
} from '../../../../store/modules/user/actions'

import { Container, CardInfo, CardsList, NewCardOption } from './styles'

import IconDelete from '../../../../images/icons/ic-delete.svg'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

const CreditCardForm = () => {
  const { creditCards } = useSelector(state => state.user)
  const dispatch = useDispatch()

  const [newCardMode, setNewCardMode] = useState(false)
  const [dataLoading, setDataLoading] = useState(true)
  const [hasCreditCard, setHasCreditCard] = useState(false)

  useEffect(() => {
    setHasCreditCard(
      creditCards && creditCards.filter(element => element != null).length
    )
  }, [])

  useEffect(() => {
    dispatch(getCreditCardsRequest())
  }, [])

  useEffect(() => {
    setDataLoading(false)
    setNewCardMode(creditCards && creditCards.length === 0)
  }, [creditCards])

  const handleNewCard = event => {
    event.preventDefault()
    setNewCardMode(true)
  }

  const handleRemoveCard = async id => {
    try {
      await removeCreditCard(id)
      dispatch(removeCreditCardState(id))

      dispatch(getCreditCardsRequest())

      toast.info('Cartão removido com sucesso')
    } catch (error) {
      if (error.data && error.data.message) {
        toast.info(error.data.message)
      }
      toast.error({ error })
    }
  }

  return (
    <Container>
      <h3>
        <FormattedMessage id="pages.edit_profile.section_3_title" />
      </h3>
      {hasCreditCard && !newCardMode ? (
        <Fragment>
          {dataLoading && (
            <div
              css={theme => css`
                margin: 30px 0;
                text-align: center;

                ${theme.queries.medium} {
                  width: 50%;
                }
              `}
            >
              <Loader />
            </div>
          )}

          <Container style={{ display: dataLoading ? 'none' : 'flex' }}>
            <div>
              {hasCreditCard && (
                <CardsList>
                  {creditCards.map(creditCard => (
                    <li key={creditCard.id}>
                      <div>
                        <input type="radio" name="creditcard" />
                        <CardInfo>
                          <span>{creditCard.holderName.toUpperCase()}</span>
                          <div>
                            <span>{creditCard.brandName}</span>{' '}
                            <span>{creditCard.cardMask}</span> -{' '}
                            <span>{creditCard.expirationDate}</span>
                          </div>
                        </CardInfo>
                      </div>

                      <IconDelete
                        onClick={() => handleRemoveCard(creditCard.id)}
                      />
                    </li>
                  ))}
                </CardsList>
              )}
            </div>
            <NewCardOption onClick={handleNewCard}>
              <FormattedMessage id="credit_card_component.add.message" />
            </NewCardOption>
          </Container>
        </Fragment>
      ) : (
        <CreditCard goBack={() => setNewCardMode(false)} />
      )}
    </Container>
  )
}

export default CreditCardForm
