/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { toast } from 'react-toastify'
import InputMask from 'react-input-mask'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import { useDispatch } from 'react-redux'

import Button from '../../../Button'
import FlexContainer from '../../../FlexContainer'

import { getAccount, setAccount } from '../../../../services/profile'

import { FormControl, FormGroupColumn } from '../../../Form'
import Loading from '../../../../images/icons/loadingbutton.svg'
import { updateUserDetails } from '../../../../store/modules/user/actions'

const Form = styled.form`
  ${({ theme }) => css`
    fieldset {
      margin: 50px 0;

      legend {
        color: ${theme.colors.celestialblue};
        font-weight: 600;
        margin-bottom: 28px;
      }
    }
    a {
      color: ${theme.colors.celestialblue};
    }
  `}
`

const ProfileForm = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const { register, handleSubmit, setValue, control } = useForm()

  const [isLoading, setLoading] = useState(false)
  const [hasCPF, setCPF] = useState(false)

  const fetchData = async () => {
    try {
      const { data } = await getAccount()

      if (data.cpf) {
        setCPF(true)
      }

      setValue([{ cpf: data.cpf }, { email: data.email }, { name: data.name }])
    } catch (error) {
      toast.info(
        intl.formatMessage({ id: 'toast_messages.error.profile_data' })
      )
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onSubmit = async ({ name, email, cpf }) => {
    setLoading(true)

    try {
      await dispatch(updateUserDetails({ name, email, cpf }))

      toast.success(
        intl.formatMessage({ id: 'toast_messages.success.info_save' })
      )
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'toast_messages.error.info_save' }))
    }

    setLoading(false)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <legend>
          <FormattedMessage id="pages.edit_profile.section_1_title" />
        </legend>

        <FormGroupColumn>
          <FormControl>
            <label htmlFor="name">
              <FormattedMessage id="pages.edit_profile.section_1_field_1" /> *
            </label>
            <input
              required
              type="text"
              name="name"
              ref={register({ required: true })}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="nome">
              <FormattedMessage id="pages.edit_profile.section_1_field_2" /> *
            </label>
            <input
              required
              type="email"
              name="email"
              ref={register({ required: true })}
            />
          </FormControl>
        </FormGroupColumn>

        <FormGroupColumn>
          <FormControl>
            <label htmlFor="cpf">
              <FormattedMessage id="pages.edit_profile.section_1_field_3" />
            </label>

            <Controller
              as={InputMask}
              control={control}
              disabled={hasCPF}
              mask="999.999.999-99"
              type="text"
              name="cpf"
              ref={register({ required: true })}
            />
          </FormControl>
        </FormGroupColumn>
      </fieldset>

      <FlexContainer
        css={theme => css`
          ${theme.queries.medium} {
            align-items: baseline;
            flex-direction: row;
            justify-content: flex-end;
          }
        `}
      >
        <Button
          type="submit"
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.97 }}
          uppercase
          css={css`
            width: 281px;
            height: 55px;
          `}
        >
          {isLoading ? <Loading /> : intl.formatMessage({ id: 'form.save' })}
        </Button>
      </FlexContainer>
    </Form>
  )
}

export default ProfileForm
