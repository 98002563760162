import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const Container = styled.section`
  ${({ theme }) => css`
    margin: 50px 0;

    fieldset {
      margin: 50px 0;

      legend {
        color: ${theme.colors.celestialblue};
        font-weight: 600;
        margin-bottom: 28px;
      }
    }

    h3 {
      color: ${theme.colors.celestialblue};
      font-weight: 600;
      margin-bottom: 28px;
    }

    a {
      color: ${theme.colors.celestialblue};
    }

    ${theme.queries.medium} {
      button {
        display: block;
        margin-left: auto;
      }
    }
  `}
`

export const CardsList = styled.ul`
  ${({ theme }) => css`
    list-style: none;
    width: 500px;

    li {
      align-items: center;
      background: ${theme.colors.aliceblue};
      border-radius: 10px;
      color: #8b8b8b;
      display: flex;
      font-size: 18px;
      justify-content: space-between;
      height: 101px;
      line-height: 22px;
      left: 363px;
      padding: 30px 45px 30px 35px;
      top: 923px;
      width: 100%;

      & + li {
        margin-top: 9px;
      }

      & > div {
        display: flex;
      }

      svg {
        cursor: pointer;
      }
    }

    ${theme.queries.small} {
      width: 100%;

      li {
        font-size: 14px;
        padding: 28px 20px 28px;
      }
    }
  `}
`

export const CardInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    ${theme.queries.medium} {
      margin-left: 26px;
    }
  `}
`

export const NewCardOption = styled.button`
  ${({ theme }) => css`
    ${theme.queries.small} {
      margin: 35px auto;
      text-decoration: underline;
    }

    ${theme.queries.medium} {
      background: ${theme.colors.aliceblue};
      border: 0;
      border-radius: 10px;
      color: #8b8b8b;
      cursor: pointer;
      font-size: 18px;
      height: 293px;
      left: 1050px;
      top: 813px;
      opacity: 1;
      width: 497px;
    }
  `}
`
