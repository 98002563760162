/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import MaskedInput from 'react-input-mask'

import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import Button from '../../../Button'
import FlexContainer from '../../../FlexContainer'

import { fetchAddress } from '../../../../services/address'
import { getAddress, setAddress } from '../../../../services/profile'

import { FormControl, FormGroupColumn } from '../../../Form'
import Loading from '../../../../images/icons/loadingbutton.svg'

const Form = styled.form`
  ${({ theme }) => css`
    fieldset {
      margin: 50px 0;

      legend {
        color: ${theme.colors.celestialblue};
        font-weight: 600;
        margin-bottom: 28px;
      }
    }
    a {
      color: ${theme.colors.celestialblue};
    }
  `}
`

const AddressForm = () => {
  const intl = useIntl()
  const { register, handleSubmit, setValue } = useForm()
  const [postalCodeValue, setPostalCode] = useState('')

  const [isLoading, setLoading] = useState(false)

  const fetchData = async () => {
    try {
      const { data: address } = await getAddress()

      setValue([
        { additionalData: address.additionalData },
        { city: address.city },
        { neighborhood: address.neighborhood },
        { number: address.number },
        { state: address.state },
        { street: address.street },
      ])

      setPostalCode(address.postalCode)
    } catch (error) {
      toast.warn(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onSubmit = async ({
    street,
    number,
    additionalData,
    neighborhood,
    city,
    state,
    postalCode,
  }) => {
    setLoading(true)

    try {
      const response = await setAddress({
        street,
        number,
        additionalData,
        neighborhood,
        city,
        state,
        postalCode: postalCodeValue,
      })

      if (response.data.status === 'error') {
        setLoading(false)
        toast.error(response.data.message)
        return
      }

      toast.success(
        intl.formatMessage({
          id: 'toast_messages.success.address_update_success',
        })
      )
    } catch (err) {
      if (err.response.data.Message) {
        toast.error(err.response.data.Message)
        return
      }
      intl.formatMessage({ id: 'toast_messages.error.address_update' })
    }

    setLoading(false)
  }

  const handleZipCodeChange = async zipCode => {
    try {
      const response = await fetchAddress(zipCode.replace('-', ''))
      const { logradouro, bairro, localidade, uf } = response.data

      setValue('street', logradouro)
      setValue('neighborhood', bairro)
      setValue('city', localidade)
      setValue('state', uf)
    } catch (error) {
      intl.formatMessage({ id: 'toast_messages.error.address' })
    }
    setLoading(false)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <legend>
          <FormattedMessage id="pages.edit_profile.section_2_title" />
        </legend>

        <FormGroupColumn>
          <FormGroupColumn>
            <FormControl>
              <label htmlFor="postalCode">
                <FormattedMessage id="pages.edit_profile.section_2_input1" /> *
              </label>
              <MaskedInput
                mask="99999-999"
                name="postalCode"
                value={postalCodeValue}
                inputRef={register({
                  required: true,
                })}
                onChange={event => setPostalCode(event.target.value)}
                onBlur={event => handleZipCodeChange(event.target.value)}
              />
            </FormControl>
            <FormControl>
              <label htmlFor="neighborhood">
                <FormattedMessage id="pages.edit_profile.section_2_input2" /> *
              </label>
              <input
                required
                type="text"
                name="neighborhood"
                ref={register({ required: true })}
              />
            </FormControl>
          </FormGroupColumn>
          <FormControl>
            <label htmlFor="street">
              <FormattedMessage id="pages.edit_profile.section_2_input3" /> *
            </label>
            <input
              required
              type="text"
              name="street"
              ref={register({ required: true })}
            />
          </FormControl>
        </FormGroupColumn>

        <FormGroupColumn>
          <FormGroupColumn className="sameLine">
            <FormControl>
              <label htmlFor="number">
                <FormattedMessage id="pages.edit_profile.section_2_input4" /> *
              </label>
              <input
                required
                type="text"
                name="number"
                ref={register({ required: true })}
              />
            </FormControl>
            <FormControl>
              <label htmlFor="additionalData">
                <FormattedMessage id="pages.edit_profile.section_2_input5" />
              </label>
              <input
                type="text"
                name="additionalData"
                ref={register({ required: false })}
              />
            </FormControl>
          </FormGroupColumn>
          <FormGroupColumn>
            <FormControl>
              <label htmlFor="city">
                <FormattedMessage id="pages.edit_profile.section_2_input6" /> *
              </label>
              <input
                required
                type="text"
                name="city"
                ref={register({ required: true })}
              />
            </FormControl>
            <FormControl>
              <label htmlFor="state">
                <FormattedMessage id="pages.edit_profile.section_2_input7" /> *
              </label>
              <input
                type="text"
                name="state"
                ref={register({ required: false })}
              />
            </FormControl>
          </FormGroupColumn>
        </FormGroupColumn>
      </fieldset>

      <FlexContainer
        css={theme => css`
          ${theme.queries.medium} {
            align-items: baseline;
            flex-direction: row;
            justify-content: flex-end;
          }
        `}
      >
        <Button
          type="submit"
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.97 }}
          uppercase
          css={css`
            width: 281px;
            height: 55px;
          `}
        >
          {isLoading ? <Loading /> : intl.formatMessage({ id: 'form.save' })}
        </Button>
      </FlexContainer>
    </Form>
  )
}

export default AddressForm
